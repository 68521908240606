import axios from "axios";
const service = axios.create({
    baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
    // withCredentials: false, // 跨域请求时发送Cookie
    timeout: 10000 // request timeout
})
service.interceptors.response.use(
    /**
     * If you want to get http information such as headers or status
     * Please return  response => response
     */

    /**
     * Determine the request status by custom code
     * Here is just an example
     * You can also judge the status by HTTP Status Code
     */
    response => {
        // console.log('response=======', response)
        const res = response.data

        if (response.config.responseType === 'blob') {
            return response
        }

        // if the custom code is not 20000, it is judged as an error.
        if (!res.success) {
            console.log('err')
            if(res.data) {
                return res.data
            }
        } else {
            return res
        }
    }
)
export default service
